<template>
    <form
        :key="update"
        :class="{
            [$style['form']]: true,
            [$style['form_simple']]: fields.length === 1,
        }"
        @submit.prevent="send"
    >
        <h2
            v-if="title"
            :class="[$style['form__title'], 'sw-title']"
            v-html="title"
        />

        <div
            v-if="text"
            :class="[$style['form__text'], 'sw-text']"
            v-html="text"
        />

        <div
            v-for="field in fields"
            :key="field.key"
            :class="$style['form__row']"
        >
            <SwFormInput
                v-if="!field.hidden"
                :name="field.key + '-' + pivotId"
                :label="field.label"
                :value="values[field.key]"
                :errors="errors[field.key + '-' + pivotId] || []"
                :class="$style['form__input']"
                :multiline="field.type === 'textArea'"
                :disabled="$store.state.isLoading || sent"
                clearable
                @input="
                    $set(values, field.key, $event);
                    $set(errors, field.key + '-' + pivotId, []);
                "
            />
        </div>

        <SwButton
            :class="$style['form__button']"
            :disabled="$store.state.isLoading || sent"
            type="submit"
            wide
        >
            {{ sent ? buttonTextAfterSubmit : buttonText }}
        </SwButton>
    </form>
</template>

<script>
import { isApolloError } from 'apollo-client'
import { getValidationErrors } from '@@/website/utils/graphql'

import { FEEDBACK } from '@@/graphQL/web'

export default {
  name: 'WebForm',

  props: {
    pivotId: {
      type: [Number, String],
      default: null
    },

    title: {
      type: String,
      default: 'Заголовок формы'
    },

    text: {
      type: String,
      default: ''
    },

    fields: {
      type: Array,
      default: () => []
    },

    buttonText: {
      type: String,
      default: 'Отправить'
    },

    buttonTextAfterSubmit: {
      type: String,
      default: 'Ожидайте звонка менеджера'
    }
  },

  data: () => ({
    values: {},
    errors: {},
    update: 0,
    sent: false
  }),

  watch: {
    fields: {
      deep: true,
      immediate: true,
      handler () {
        this.sync()
      }
    }
  },

  methods: {
    sync () {
      this.values = this.fields.reduce((values, field) => ({ ...values, [field.key]: '' }), {})
      this.update++
    },

    async send () {
      if (this.$store.state.admin) {
        this.$store.commit('modals/open', 'forbidden')
        return
      }

      if (this.$store.state.isLoading || this.sent) {
        return
      }

      this.$store.commit('lock')

      try {
        const { data } = await this.$apollo.mutate({
          mutation: FEEDBACK,
          variables: {
            pivot_id: this.pivotId,
            input: { ...this.values }
          }
        })

        if (data.feedback.success) {
          this.sync()
          this.sent = true
        }
      } catch (e) {
        const errors = getValidationErrors(e)
        if (isApolloError(e) && errors !== undefined) {
          this.errors = errors
        } else {
          console.error(e)
        }
      }

      this.$store.commit('unlock')
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.form {
  width: 100%;
  max-width: 426px;
  box-sizing: border-box;
  padding: 32px 24px;
  background-color: @grey-400;
  border-radius: 12px;

  &_simple {
    max-width: 522px;
  }

  &__title {
    margin: 0;
    width: 100%;
  }

  &__text {
    display: block;
    font-size: 16px;
    margin-bottom: 24px;
  }

  &__input label {
    font-style: normal;
    font-weight: 400;
  }

  &__button {
    max-width: 100%;
    white-space: normal;
  }

  &__title + &__text {
    margin-top: 8px;
  }

  &__title + &__row {
    margin-top: 24px;
  }

  &__row + &__button {
    margin-top: 8px;
  }

  &__row + &__row {
    margin-top: 8px;
  }
}
</style>
